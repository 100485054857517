import UAParser from 'ua-parser-js';

export default {
  computed: {
    isAmazonDevice() {
      try {
        return UAParser(window.navigator.userAgent).device.vendor === 'Amazon';
      } catch (e) {
        console.error('failed to parse user-agent', e);
        return false;
      }
    },
  },
};
